.sandbox {
    width: 60%; /* Reduced from 100% to 60% (20% reduction on each side) */
    max-width: 1200px; /* You can adjust this value if needed */
    margin: 0 auto; /* This centers the component */
    padding: 0 20px;
    box-sizing: border-box;
}

.sandbox form {
  width: 300px; /* Set a fixed width for the form */
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* Center the form horizontally */
}
