body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrollbar */
}

.domain-manager {
  width: 60%;
  max-width: 1200px;
  margin: 10vh auto 0; /* Changed to margin-top instead of top property */
  padding: 40px 20px;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

.domain-manager h2 {
  font-size: 32px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.domain-manager p {
  color: #666;
  margin-bottom: 20px;
}

.domain-form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.records-section {
  margin-top: 20px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-x: auto; /* Allow horizontal scrolling for table if needed */
}

.records-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  table-layout: fixed;
}

.records-table th,
.records-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.records-table th {
  background-color: #f8f8f8;
  font-weight: bold;
  color: #333;
}

.records-table td {
  color: #1a1a1a;
}

/* Remove different styling for Data and TTL columns */
.records-table td:nth-child(3),
.records-table td:nth-child(4) {
  color: #1a1a1a; /* Same as other cells */
}

.records-table tr:hover {
  background-color: #f5f5f5;
}

.info-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #ccc;
  color: white;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
}

.delete-btn,
.edit-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.delete-btn {
  color: #e74c3c;
}

.edit-btn {
  color: #3498db;
}

/* Style for "Can't delete" and "Can't edit" text */
td:nth-last-child(-n+2) {
  color: #999;
}

/* Hide any question mark icons in table headers */
.records-table th .info-icon,
.records-table th::after {
  display: none !important;
}

/* Ensure each column takes up equal space */
.records-table th:nth-child(1),
.records-table td:nth-child(1) { width: 15%; }

.records-table th:nth-child(2),
.records-table td:nth-child(2) { width: 20%; }

.records-table th:nth-child(3),
.records-table td:nth-child(3) { 
  width: 50%; 
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.records-table th:nth-child(4),
.records-table td:nth-child(4) { width: 15%; }

/* Add responsive behavior */
@media (max-width: 1200px) {
  .domain-manager {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .domain-manager {
    width: 90%;
    padding: 20px 10px;
    margin-top: 5vh; /* Adjusted for smaller screens */
  }
  
  .records-table th,
  .records-table td {
    padding: 8px;
    font-size: 12px;
  }
}

.record-tabs {
  display: flex;
  border-bottom: 2px solid #e0e0e0;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  margin-bottom: -2px;
}

.tab-button:hover {
  background-color: #f5f5f5;
}

.tab-button.active {
  border-bottom: 2px solid #00a1e0;
  color: #00a1e0;
}
