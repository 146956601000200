.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1e1e1e;
  color: white;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.animation-wrapper {
  width: 200px; /* Adjust based on your animation size */
  height: 200px; /* Adjust based on your animation size */
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-container p {
  margin-top: 20px;
  font-size: 18px;
}

.loading-text {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
}
