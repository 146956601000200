@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #1a1a1a 0%, #2c3e50 100%);
}

.login-box {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 400px;
  width: 90%;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.login-box.visible {
  opacity: 1;
  transform: translateY(0);
}

.app-title {
  color: #333;
  font-size: 2.8em;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.03em;
  font-family: "Segoe UI", "Helvetica Neue", Arial, sans-serif;
}

.app-description {
  color: #666;
  margin-bottom: 30px;
  font-size: 1.1em;
  line-height: 1.4;
  font-weight: 400;
}

.login-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  width: 100%;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.login-button:hover {
  background-color: #2980b9;
}

.login-button:active {
  transform: scale(0.98);
}

@media (max-width: 480px) {
  .login-box {
    padding: 30px;
  }
  
  .app-title {
    font-size: 2.4em;
  }
}
