.ms-domain-onboard-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.ms-domain-onboard {
  flex-grow: 1;
  padding: 40px;
  max-width: 800px;
  width: calc(100% - 250px);
  background-color: #ffffff;
  border-radius: 0 12px 12px 0;
}

.ms-domain-onboard h2 {
  font-size: 32px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.form-group {
  margin-bottom: 25px;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #34495e;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.form-group input {
  width: 100%;
  padding: 12px 15px;
  border: 2px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  box-sizing: border-box; /* This ensures padding is included in the width */
}

.form-group input:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
}

.submit-button, .verify-button, .back-button, .config-button, .apply-config-button {
  width: 100%;
  padding: 14px;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.submit-button {
  background-color: #3498db;
}

.submit-button:hover {
  background-color: #2980b9;
}

.verify-button, .config-button, .apply-config-button {
  background-color: #2ecc71;
}

.verify-button:hover, .config-button:hover, .apply-config-button:hover {
  background-color: #27ae60;
}

.back-button {
  background-color: #e74c3c;
}

.back-button:hover {
  background-color: #c0392b;
}

.submit-button:disabled, .verify-button:disabled, .config-button:disabled, .apply-config-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.message {
  margin-top: 20px;
  padding: 15px;
  background-color: #e8f6fd;
  border: 2px solid #3498db;
  border-radius: 6px;
  color: #2c3e50;
  font-size: 16px;
  font-weight: 500;
}

.response-data {
  margin-top: 25px;
  padding: 25px;
  background-color: #f9f9f9;
  border: 2px solid #ddd;
  border-radius: 6px;
}

.response-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.response-table th,
.response-table td {
  padding: 15px 20px;
  text-align: left;
  border-bottom: 1px solid #ecf0f1;
}

.response-table th {
  background-color: #3498db;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.response-table tr:last-child td {
  border-bottom: none;
}

.response-table tr:nth-child(even) {
  background-color: #f8f9fa;
}

.response-table tr:hover {
  background-color: #e8f6fd;
}

.configuration-complete {
  text-align: center;
  padding: 50px 20px;
  background-color: #f0f9ff;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.configuration-complete h2 {
  color: #27ae60;
  margin-bottom: 25px;
  font-size: 36px;
}

.success-message {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 35px;
  color: #34495e;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .ms-domain-onboard-container {
    flex-direction: column;
  }

  .ms-domain-onboard {
    width: 100%;
    padding: 20px;
    border-radius: 0 0 12px 12px;
  }
}

@media (max-width: 480px) {
  .ms-domain-onboard {
    padding: 15px;
  }
}