.sidebar-ms-domain-onboard {
  width: 250px;
  padding: 30px 20px;
  background-color: #f8f9fa;
  border-right: 1px solid #e0e0e0;
  height: 100%;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
}

.step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  position: relative;
}

.step-indicator {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e0e0e0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

.step.active .step-indicator {
  background-color: #3498db;
  transform: scale(1.1);
}

.step.completed .step-indicator,
.step.active.completed .step-indicator,
.step.active.last-step .step-indicator {
  background-color: #2ecc71;
}

.step-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step-name {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  text-align: left; /* Ensure text is left-aligned */
  line-height: 30px; /* Match the height of the indicator for vertical alignment */
}

.step-connector {
  position: absolute;
  left: 15px;
  top: 30px;
  bottom: -30px;
  width: 2px;
  background-color: #e0e0e0;
}

.step.completed .step-connector {
  background-color: #2ecc71;
}

.step:last-child .step-connector {
  display: none;
}

@media (max-width: 768px) {
  .sidebar-ms-domain-onboard {
    width: 100%;
    padding: 20px;
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
  }

  .step {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    margin-right: 20px;
    flex: 1;
  }

  .step-indicator {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .step-name {
    text-align: center; /* Center text for mobile view */
    font-size: 14px;
    line-height: normal;
  }

  .step-connector {
    left: 50%;
    top: 30px;
    bottom: auto;
    width: 100%;
    height: 2px;
  }
}
