.emarsys-dns-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.emarsys-dns {
  flex-grow: 1;
  padding: 40px;
  max-width: 800px;
  width: calc(100% - 250px);
  background-color: #ffffff;
  border-radius: 0 12px 12px 0;
}

.emarsys-dns > div {
  max-width: 100%;
  margin: 0 auto;
}

.emarsys-dns h2 {
  font-size: 32px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.form-group {
  margin-bottom: 25px;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #34495e;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.form-group input {
  width: 100%;
  padding: 12px 15px;
  border: 2px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.form-group input:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
}

.submit-button, .apply-button, .back-button {
  width: 100%;
  padding: 14px;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.submit-button, .apply-button {
  background-color: #3498db;
}

.submit-button:hover, .apply-button:hover {
  background-color: #2980b9;
}

.back-button {
  background-color: #e74c3c;
}

.back-button:hover {
  background-color: #c0392b;
}

.submit-button:disabled, .apply-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.message {
  margin: 20px 0;
  padding: 15px;
  border-radius: 6px;
  font-weight: 600;
  text-align: center;
}

.response-view {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.response-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
}

.action-buttons {
  padding: 20px;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
}

.tabbed-response {
  margin-top: 20px;
}

.response-data {
  margin-bottom: 20px;
}

.response-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.response-table th,
.response-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.response-table th {
  background-color: #3498db;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.response-table tr:last-child td {
  border-bottom: none;
}

.response-table tr:nth-child(even) {
  background-color: #f8f9fa;
}

.response-table tr:hover {
  background-color: #e8f6fd;
}

.response-table .section-header th {
  background-color: #f0f0f0;
  font-weight: bold;
  text-align: left;
  padding: 10px;
  border-bottom: 2px solid #ddd;
}

.configuration-complete {
  text-align: center;
  padding: 50px 20px;
  background-color: #f0f9ff;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.configuration-complete h2 {
  color: #27ae60;
  margin-bottom: 25px;
  font-size: 36px;
}

.success-message {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 35px;
  color: #34495e;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.emarsys-loading-spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  margin-right: 10px;
}

.emarsys-loading-message {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

/* Ensure the spinner is visible on both light and dark backgrounds */
@media (prefers-color-scheme: dark) {
  .spinner {
    border-color: #333;
    border-top-color: #3498db;
  }
  
  .loading-message {
    color: #ccc;
  }
}

/* Adjust the spinner for buttons */
.submit-button .loading-spinner,
.apply-button .loading-spinner {
  flex-direction: row;
  padding: 0;
}

.submit-button .spinner,
.apply-button .spinner {
  width: 20px;
  height: 20px;
  border-width: 2px;
  margin-right: 10px;
}

.submit-button .loading-message,
.apply-button .loading-message {
  margin-top: 0;
  margin-left: 10px;
  font-size: 14px;
  color: white; /* To ensure visibility on button background */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay .loading-spinner {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.tabbed-response {
  margin-top: 20px;
}

.tab-headers {
  display: flex;
  border-bottom: 2px solid #3498db;
}

.tab-button {
  padding: 10px 20px;
  background-color: #f8f9fa;
  border: none;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.tab-button:hover {
  background-color: #e8f6fd;
}

.tab-button.active {
  background-color: #3498db;
  color: white;
}

.tab-content {
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 5px 5px;
}

@media (max-width: 768px) {
  .emarsys-dns-container {
    flex-direction: column;
  }

  .emarsys-dns {
    width: 100%;
    padding: 20px;
    border-radius: 0 0 12px 12px;
  }
}

@media (max-width: 480px) {
  .emarsys-dns {
    padding: 15px;
  }
}
