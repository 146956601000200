.sidebar-emarsys-dns {
  width: 250px;
  padding: 20px;
  background-color: #f8f9fa;
  border-right: 1px solid #e9ecef;
  height: 100%;
}

.step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative;
}

.step-indicator {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  flex-shrink: 0;
}

.step.completed .step-indicator,
.step.active.last-step .step-indicator {
  background-color: #28a745;
  color: white;
}

.step.active .step-indicator {
  background-color: #007bff;
  color: white;
}

.step-content {
  flex-grow: 1;
}

.step-name {
  font-weight: bold;
  color: #343a40;
  margin-bottom: 5px;
}

.step.completed .step-name,
.step.active .step-name {
  color: #007bff;
}

.step-connector {
  position: absolute;
  left: 15px;
  top: 30px;
  bottom: -20px;
  width: 2px;
  background-color: #e9ecef;
}

.step.completed .step-connector {
  background-color: #28a745;
}

.step:last-child .step-connector {
  display: none;
}

/* Icons */
.step-indicator svg {
  width: 16px;
  height: 16px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar-emarsys-dns {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #e9ecef;
    padding: 15px;
  }

  .step {
    margin-bottom: 15px;
  }

  .step-indicator {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .step-connector {
    left: 12px;
    top: 25px;
  }
}

