.left-nav {
  width: 80%;
  max-width: 200px;
  background-color: #212121;
  padding: 20px;
  position: fixed;
  top: 50px;
  left: 0;
  height: calc(100vh - 50px);
  overflow-y: auto;
  transition: all 0.3s ease;
  animation: slideIn 1s ease 0.5s both; /* Adjusted animation */
  opacity: 0; /* Start with the nav invisible */
}

.left-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.left-nav > ul {
  padding-top: 10px; /* Add some top padding to the main ul */
}

.left-nav li {
  margin-bottom: 10px;
}

/* Base styles for all nav links */
.left-nav .nav-link,
.left-nav .dropdown > span.nav-link {
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  background-color: transparent;
}

/* Styles for top-level items */
.left-nav > ul > li > .nav-link,
.left-nav > ul > li > .dropdown > span.nav-link {
  font-weight: bold;
}

/* Hover effects */
.left-nav .nav-link:hover,
.left-nav .dropdown > span.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateX(5px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Active state */
.left-nav .nav-link.active {
  background-color: #285ae6;
  color: white;
}

/* Dropdown specific styles */
.left-nav .dropdown {
  position: relative;
}

.left-nav .arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.left-nav .dropdown.open .arrow {
  transform: rotate(-135deg);
}

.dropdown-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}

.dropdown.open .dropdown-content {
  max-height: 200px; /* Adjust this value based on your content */
  opacity: 1;
}

.dropdown-content .nav-link {
  padding-left: 30px;
  color: white;
  opacity: 1;
  visibility: visible;
}

/* Animation for the entire nav on page load */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-50px); /* Increased initial offset */
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
