.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.top-nav {
  background-color: #333;
  color: white;
  padding: 10px;
}

.top-nav ul {
  list-style-type: none;
  padding: 0;
}

.top-nav ul li {
  display: inline;
  margin-right: 20px;
}

.top-nav ul li a {
  color: white;
  text-decoration: none;
}

.content-wrapper {
  display: flex;
  flex-grow: 1;
  margin-top: 50px; /* Adjust based on your top nav height */
  margin-left: 200px; /* Adjust based on your left nav width */
  overflow-y: auto; /* Allow scrolling for the main content */
}

.left-nav {
  width: 200px;
  background-color: #f1f1f1;
  padding: 20px;
}

.left-nav ul {
  list-style-type: none;
  padding: 0;
}

.left-nav ul li {
  margin-bottom: 10px;
}

.left-nav ul li a {
  text-decoration: none;
  color: #333;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
}

/* Add these new styles for the Login component */
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.login-container h2 {
  margin-bottom: 20px;
}

.login-container button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
