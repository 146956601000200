.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.logo-container {
  width: 70vw;  /* Increased from previous value */
  max-width: 1000px;  /* Increased for larger screens */
  opacity: 0;
  transform: scale(0.5);
  animation: logoIntro 1.5s ease-out forwards;
}

.logo-image {
  width: 100%;
  height: auto;
  display: block;
}

@keyframes logoIntro {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .logo-container {
    width: 85vw;  /* Slightly larger on smaller screens */
  }
}

@media (min-width: 1200px) {
  .logo-container {
    width: 60vw;  /* Slightly smaller on very large screens */
  }
}

.image-container {
  margin: 20px 0;
}

.home-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px; /* Optional: adds rounded corners */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow */
}
