.top-nav {
  background-color: #1e1e1e;
  color: white;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  padding-right: 0; /* Remove right padding */
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  width: auto;
  margin-right: 20px;
  object-fit: contain;
}

.top-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.top-nav ul li {
  margin-right: 20px;
}

.top-nav ul li:last-child {
  margin-right: 0;
}

.top-nav ul li a {
  color: white;
  text-decoration: none;
}

.user-info {
  position: relative;
  height: 100%; /* Make it full height of the nav bar */
}

.account-button {
  height: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: inherit; /* Use the same font as the rest of the app */
  font-size: 14px; /* Adjust if needed to match other components */
  font-weight: bold; /* Match the bold style of top-level items in LeftNav */
  color: white;
  text-transform: uppercase; /* Optional: if you want it to stand out more */
  letter-spacing: 0.5px; /* Slight letter spacing for better readability */
}

.account-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dropdown-arrow {
  margin-left: 5px;
  font-size: 10px;
  transition: transform 0.3s ease;
}

.account-button[aria-expanded="true"] .dropdown-arrow {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%; /* Position it right below the nav bar */
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none; /* Remove top border */
  border-radius: 0 0 4px 4px; /* Round only bottom corners */
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  width: 250px;
  z-index: 1001;
}

.dropdown-header {
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
}

.dropdown-header strong {
  display: block;
  font-size: 12px;
  color: #666;
}

.dropdown-header div {
  font-size: 14px;
  color: #333;
  margin-top: 5px;
}

.logout-button {
  width: 100%;
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.logout-button:hover {
  background-color: #f0f0f0;
}

/* Adjust main content to account for fixed top nav */
body {
  padding-top: 50px; /* Should match the height of .top-nav */
}
